import React from 'react';
import { Link } from 'gatsby';

import HomeLayout from '../layout/homepage';

import StatementsBackground from '../components/images/statementsBackground';
import Ryan from '../components/images/ryan';

import Fade from 'react-reveal/Fade';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// SEO imports
import SEO from '../components/seo';
import ogImage from '../assets/images/og-images/adaptix.jpg';

const AboutPage = () => (
	<HomeLayout>
		<SEO
			title="About | Strong Mind Performance Consulting"
			description="Ryan Foose (Founder + Mental Performance Consultant) Ryan is a Mental Performance Consultant."
			image={ogImage}
		/>
		<div id="about">
			<section className="statements">
				<Fade>
					<StatementsBackground />
				</Fade>
				<div className="statements__content">
					<div className="container">
						<Fade>
							<h1 className="shout">
								Strong Mind <br />Mission & Vision
							</h1>
							<Tabs>
								<TabList>
									<Tab className="whisper">Mission</Tab> |
									<Tab className="whisper">Vision</Tab>
								</TabList>

								<TabPanel>
									<Fade>
										<p className="talk">
											Our mission is to help individuals reach peak performance by training the
											mind. We realize that sometimes your mind can get in the way. Our goal is to
											remove that barrier and aid them in unlocking their full potential.
										</p>
									</Fade>
								</TabPanel>
								<TabPanel>
									<Fade>
										<p className="talk">
											Our vision is to change the way individuals look at using their minds.
											Strong Mind’s foundation is built on the idea that the mind plays an
											important role in performance. Whether it’s working with an athlete, coach,
											or athletic director; we want individuals to have the resources to train
											their mind properly and unlock their full potential.
										</p>
									</Fade>
								</TabPanel>
							</Tabs>
						</Fade>
					</div>
				</div>
			</section>
			<section className="story">
				<div className="container">
					<Fade>
						<h2 className="yell">Our Story</h2>
						<p className="talk">
							In 2015, Strong Mind Performance Consulting was founded to help individuals perform at their
							best by training their mind. We have four core values when working with individuals:
							Compassion, Empathy, Discipline, & Work Ethic. Since 2015, we have worked with individuals
							ranging from youth to professional sports. Not only have we worked with athletes; we have
							also worked with numerous coaches to help them develop their coaching philosophy and create
							a “Championship Mindset” culture. Outside of working with athletes and teams we created a
							Leadership Academy at Mira Costa H.S. in southern California. Our Academy educates team
							captains on the values, characteristics, and styles of effective leadership. At Strong Mind,
							we’re passionate about helping individuals be their best!
						</p>
					</Fade>
				</div>
			</section>
			<section className="bio">
				<div className="container">
					<div className="bio__thumbnail">
						<Fade>
							<Ryan />
							<a className="talk" href="mailto:ryan@strongmindpc.com">
								Ryan@StrongMindPC.com
							</a>
						</Fade>
					</div>
					<div className="bio__content">
						<Fade>
							<h2 className="shout">
								Meet <span>Ryan</span>, of Strong Mind Performance Consulting
							</h2>
							<p className="whisper">Ryan Foose (Founder + Mental Performance Consultant)</p>
							<br />
							<br />
							<p className="talk">
								Ryan is a Mental Performance Consultant under the Association for Applied Sport
								Psychology (AASP). Ryan has consulting experience with a plethora of sports and has
								worked with athletes and teams ranging from the youth level to the professional level.
								Ryan has been sought out by numerous athletes, teams, and athletic departments to teach
								mental skills and team development through sport psychology principles. Ryan received
								his BS in Sport & Exercise Psychology from West Virginia University, and his MS in Sport
								& Performance Psychology from Ithaca College
							</p>
						</Fade>
					</div>
				</div>
			</section>
		</div>
	</HomeLayout>
);

export default AboutPage;
